import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToGetFollowers = ({ location }) => {
  const title = "一気に増やす！Twitterのフォロワー獲得方法【注意点も解説】"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="一気に増やす！Twitterのフォロワー獲得方法【注意点も解説】"
          pagedesc="Twitterのフォロワー数が伸び悩んでいませんか？実は簡単な方法で一気にフォロワーを増やすことができるんです。本記事ではその方法と利用上の注意をご説明します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-get-followers.jpg"
          pagepath="/blog/how-to-get-followers"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>一気に増やす！Twitterのフォロワー獲得方法【注意点も解説】</h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月14日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="会議をしている4人の男性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-get-followers.jpg"
                className="w-full"
              />
            </figure>
            <p>
              数多くのSNSの中でも、Twitterを利用するメリットは高い拡散性と投稿の手軽さです。Twitterを上手く活用できれば、世の中に影響を与えることができるかもしれません。しかし、その特性ならではの危険性もあります。本記事ではより多くのフォロワーを獲得し、Twitterを楽しむために押さえるべきコツと注意点をご紹介します。
            </p>
            <div className="blog__border-highlight">
              <p>
                <span className="emoji">&#10002;&#65039;</span>
                　企業アカウント運営については、こちらの記事もおすすめです
              </p>
              <p>
                <a
                  href="/blog/2022-fan-marketing"
                  target="_blank"
                  rel="noreferrer"
                >
                  【2022年版】Twitter
                  企業アカウント運用のコツと注意点（ファンマーケティング）
                </a>
              </p>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    フォロワーを増やすコツ
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        1. プロフィールを変更する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        2. 定期的にツイートする・ツイート数を増やす
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        3. ツイート内容を工夫する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        4.積極的にフォロー・いいね・リツイートする
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        5. 分析する
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    Twitter利用の注意点
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">フォロワーを増やすコツ</h2>
              <p>
                簡単なコツをまとめました。すぐに活用できるので、ぜひ最後までご覧ください。
              </p>
            </div>
            <div>
              <h3 id="id2">1. プロフィールを変更する</h3>
              <p>
                初期設定のままでは、あなたがどのような人物かわかりません。他のユーザーをフォローしたり、ツイートをしても不審感を抱かれる可能性があります。あなたについて周知し、好感を持ってもらうためにもきちんとプロフィールを設定しましょう。ということで押さえるべきポイントをご紹介します。
              </p>
              <ul className="blog__border-list">
                <li>
                  アカウントの名前
                  <p>アカウント名には「名前」と「ユーザー名」があります。</p>
                  <p>
                    「名前」はそのアカウントの呼び名やニックネームです。本名でなくても良いので、あなたが誰なのか特定しやすい名前が良いでしょう。職業や肩書き、特定のキーワードを含めるのもおすすめです。
                  </p>
                  <p>
                    設定としては1〜50文字の文字制限内であれば、英数字、日本語、絵文字、記号を自由に使用できます。しかしツイートやDM上では省略されて表示されるので、伝えたい内容は前半に書きましょう。
                  </p>
                  <p>
                    「ユーザー名」は@から始まるIDのようなものです。文字数は5〜15文字で、英数字と_のみ使用可能です。また、「Twitter」や「admin」、既存のユーザー名は使用できません。
                  </p>
                  <p>
                    こちらは主に検索やユーザーを特定する際に使われます。あまり注目されることはありませんが、こちらもご自身に関係のあるワードを含めると良いでしょう。
                  </p>
                </li>
                <li>
                  アイコン・ヘッダー画像
                  <p>
                    アイコンとは円形の画像で、Twitter上の様々な場面で表示されるので言わばあなたの「顔」となります。だからこそご自身の写真やアバター、ロゴなど特徴的で特定しやすいものが好ましいです。
                  </p>
                  <p>
                    ヘッダー画像とはプロフィールの背景画像です。カバー写真とも呼ばれます。アイコンに加え、あなたがどんな人物なのか視覚的に瞬時に印象付けることができるのでとても重要です。一番アピールしたいポイントを画像として設定すれば、好感を得たユーザーに自己紹介やツイートを見てもらえる可能性が高まります。
                  </p>
                  <p>
                    サイズは1500×500pxと横長なので、画像の主となる部分が切れないようご注意ください。
                  </p>
                </li>
                <li>
                  自己紹介文
                  <p>
                    アカウント名やアイコン・ヘッダー画像、ツイート等であなたに興味を持つと、やはりどんな人物なのか詳しく知りたくなるものです。
                  </p>
                  <p>
                    自己紹介にツイートの意図や自身の特長、経歴、趣味などを記載することで親近感や信頼感を得ることができます。
                  </p>
                  <p>
                    注意点として最大文字数が160文字となっているので、簡潔に伝えたいことだけを書きましょう。また読みやすさも大切なので、改行や絵文字等を使って工夫しましょう。
                  </p>
                </li>
                <li>
                  URL
                  <p>
                    Twitter以外のSNSや共有したいWebサイト等があれば、URLを貼り付けましょう。SNSごとに特性が異なるので、あなたの新たな一面を見てもらえるかもしれません。
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id3">2. 定期的にツイートする・ツイート数を増やす</h3>
              <p>
                Twitterの大きなメリットは、拡散力の高さです。あなたの発信したい想いや考えを、定期的かつ頻繁に発信しましょう。
              </p>
              <p>
                頻度や投稿時間としては朝、昼、夕方〜夜が良いと言われています。その理由として、朝は通勤・通学時間、昼は休憩時間、夕方〜夜は通勤・通学に加え、帰宅しSNSを閲覧する人が多いからです。定期的に発信することで、既存フォロワーの記憶に定着し、他ユーザーの目にも留まりやすくなり新規フォロワー獲得に繋がります。
              </p>
              <p>
                だからといって内容が何でも良いわけではありません。ツイート内容については次項をご覧ください。
              </p>
            </div>
            <div>
              <h3 id="id4">3. ツイート内容を工夫する</h3>
              <p>
                フォロワーやユーザーの心を掴むには、ツイート内容が重要です。次の項目を参考にツイートしてはいかがでしょうか。
              </p>
              <ul className="blog__border-list">
                <li>
                  共感を呼ぶ内容
                  <p>
                    あるあるや体験談など、共感を得られやすい内容をツイートします。そうするとフォロワーは親近感を覚え、仲間意識が生まれます。
                  </p>
                </li>
                <li>
                  面白い内容
                  <p>
                    ネタやギャグなどの笑えるツイートはとても人気があります。
                  </p>
                </li>
                <li>
                  元気が出る内容
                  <p>
                    気軽につぶやくことができるのがTwitterのメリットです。そのためネガティブな内容も多いので、元気や勇気の出るツイートは喜ばれます。
                  </p>
                </li>
                <li>
                  豆知識・学問やノウハウなどの有益な情報
                  <p>
                    ユーザーに需要のある情報を発信し続けることは、フォロワー離れ防止にも効果があります。
                  </p>
                </li>
                <li>
                  ハッシュタグをつけて投稿する
                  <p>
                    特定の目的をもったユーザーに向け発信できる、ツイートの拡散性が上がる、というメリットがあります。
                  </p>
                </li>
              </ul>
              <p>
                他にも、画像をつける、見栄えを良くするといった工夫もできます。画像や動画、GIFをつけることで画面に表示される面積が大きくなり、ユーザーの目に入る可能性が高まります。より多くのユーザーの目に留まるにはインパクトやツイートの第一印象が大切です。文章量や余白、画像の見栄えを考えると良いでしょう。
              </p>
              <p>
                また、内容に好感を持ったフォロワーやユーザーにリツイートしてもらえる可能性があります。「類は友を呼ぶ」というように、リツイートしたユーザーを見ている人々はそのユーザーと興味が似ている可能性が高いので、より効果的にフォロワーを増やすことができるでしょう。
              </p>
              <p>
                注意点として、ツイート本文の文字数は最大140文字までとなっています。発信したい内容は簡潔にまとめましょう。
              </p>
            </div>
            <div>
              <h3 id="id5">4. 積極的にフォロー・いいね・リツイートする</h3>
              <p>
                発信するだけでなく、興味のあるユーザーやツイートを積極的にフォロー・いいね・リツイートすることもおすすめします。フォローに関して注意すべきなのが、前期した通り「興味のある」ユーザーのみを対象にする、ということです。無闇にフォローすると、ただフォロワーを増やしたいだけのユーザーと見なされ、アカウントの質を下げてしまいます。
              </p>
            </div>
            <div>
              <h3 id="id6">5. 分析する </h3>
              <p>
                Twitterには「<strong>ツイートアクティビティ</strong>
                」という機能があり、あなたの何のツイートを何人が見たか、興味を持ったかを数字で確認することができます。エンゲージメント・インプレッションの数値を読みとり、ツイート内容や時間帯などの改善策を考えましょう。
              </p>
              <div className="blog__border-highlight">
                <p>
                  <span className="emoji">&#10002;&#65039;</span>
                  　合わせて読みたい
                </p>
                <p>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id7">Twitter利用の注意点 </h2>
              <p>
                Twitterを利用する上で、注意すべきルールがあります。場合によってはペナルティとしてアカウントを凍結される可能性があるので、確実に押さえておきましょう。具体的には以下の通りです。
              </p>
              <ol className="blog__border-list">
                <li>
                  過激な内容
                  <p>
                    過度な暴力的内容、性的内容、自傷行為など他ユーザーを不快にさせる投稿は禁止です。
                  </p>
                </li>
                <li>
                  誹謗中傷や脅迫、個人情報の流出
                  <p>
                    ヘイト行為、脅迫めいた発言、本人の許可のない個人情報の流出も禁止です。Twitter内だけでなく、犯罪行為なので絶対にやめましょう。
                  </p>
                </li>
                <li>
                  フォロー数の制限
                  <p>
                    スパムなどの迷惑行為を防止するため、1日にフォローできる数は最大400アカウント、認証Twitterアカウントの場合は最大1,000アカウントまでと決められています。
                  </p>
                </li>
              </ol>
              <p>
                1〜3の項目に関して、詳しくはTwitter公式サイトをご覧ください。
              </p>
              <p>
                参考：
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-rules"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterルール
                </a>
              </p>
              <p>
                参考：
                <a
                  href="https://help.twitter.com/ja/using-twitter/twitter-follow-limit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterのフォローについて
                </a>
              </p>
            </div>
            <div>
              <h2 id="id8">まとめ</h2>
              <p>
                3億人以上のユーザーが存在するTwitter。Twitterの使い方は人それぞれです。しかしフォロワーを獲得したいならば、ただ自分の想いを発信するのではなく、ユーザーにとってメリットのある内容を発信することが重要です。ルールに則り、簡単な工夫と需要あるツイートでファンを増やしましょう。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プロが教えるTwitterインスタントウィン・キャンペーンのやり方。売上アップに効果的なのは「ファンマーケティング」
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンバナー【実例20選】デザイナーが解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2022-fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【2022年版】Twitter
                    企業アカウント運用のコツと注意点（ファンマーケティング）
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HowToGetFollowers
